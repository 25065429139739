import {destroy, get, post, put} from "services/LegalApi/base";

export const Transaction = {
    index: () => get("/transaction/"),
    indexIncludeDocuments: () => get("/transaction/", {params: {include: "documents"}}),
    indexIncludeDocumentsAndValues: () => get("/transaction/", {params: {include: "documents,values"}}),
    single: (id) => get(`/transaction/${id}`),
    create: (params) => post("/transaction/", params),
    update: (id, params) => put(`/transaction/${id}`, params),
    remove: (id) => destroy(`/transaction/${id}`),
};