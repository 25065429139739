import axios from "axios";
import {loginRequest} from "authConfig";
import {msalInstance} from "App";

export function initiateApiClient(baseUrl) {
    const apiClient = axios.create({baseURL: baseUrl, timeout: 30000});

    apiClient.interceptors.request.use(
        (config) => configInterceptor(config),
        (error) => Promise.reject(error)
    );

    return apiClient;
}

function configInterceptor(config) {
    return requestAccessToken().then(response => {
        config.headers["Authorization"] = `Bearer ${response.accessToken}`;
        return config;
    });
}

function requestAccessToken() {
    const request = {
        ...loginRequest,
        account: msalInstance.getAllAccounts()[0]
    };

    return msalInstance.acquireTokenSilent(request).catch(() => msalInstance.acquireTokenRedirect(request));
}